<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        The enthalpy of vaporization, <stemble-latex content="$\Delta\text{H}_\text{vap},$" /> is
        determined from the Clausius-Clapeyron equation:
      </p>

      <p class="pl-8 mb-5">
        <stemble-latex
          content="$\text{ln VP}=-\displaystyle{\frac{\Delta\text{H}_\text{vap}}{\text{R}}\frac{1}{\text{T}}}+\text{C}$"
        />
      </p>

      <p>
        Note that this equation is linear (i.e. of the form y = mx + b). Use Google Sheets and the
        data tabulated below to calculate the necessary values to plot ln(VP) versus the inverse
        temperature (i.e. 1/T).
      </p>

      <v-simple-table style="max-width: 319px" class="mb-6 pl-8">
        <thead>
          <tr>
            <td style="font-weight: bold">Trial</td>
            <td style="font-weight: bold; text-align: center">T (K)</td>
            <td style="font-weight: bold; text-align: center">VP (mmHg)</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(xValue, i) in xValues" :key="xValue">
            <td>{{ i + 1 }}</td>
            <td style="text-align: center">
              {{ xValues[i].toFixed(1) }}
            </td>
            <td style="text-align: center">
              {{ lnVpValues[i].toFixed(1) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p style="font-weight: bold">
        Copy the data from the table into your spreadsheet so that 'Trial' is in cell A1.
      </p>

      <p>
        <i
          >A new column is inserted on the spreadsheet between the 'T (K)' and 'VP (mmHg)' columns
          for the 1/T calculation.
        </i>
      </p>

      <p class="mb-n3 pl-4">
        a) What formula should you type into the cell directly to the right of 298.2 (i.e. in cell
        C2) in order to calculate 1/T?
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3 pl-4">b) How is the calculation completed for Trials 2-6?</p>

      <v-radio-group v-model="inputs.input2" class="ml-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p>
        <i>In column E, calculate ln VP. </i>
      </p>

      <p class="mb-n3 pl-4">
        c) What formula should you type into the cell directly to the right of 116.2 (i.e. in cell
        E2) in order to calculate ln VP?
      </p>

      <v-radio-group v-model="inputs.input3" class="ml-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options3"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3 pl-4">d) How is the calculation completed for Trials 2-6?</p>

      <v-radio-group v-model="inputs.input4" class="ml-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options4"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p>
        <i>Plot Column C vs Column E. </i>
      </p>

      <p class="mb-n3 pl-4">e) After highlighting both columns, what is the next step?</p>

      <v-radio-group v-model="inputs.input5" class="ml-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options5"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p>
        The chart should be a Scatter chart. Change it if it is something different. In the Chart
        editor, click on <b>Customize</b>, then choose <b>Series</b>. Check the boxes next to
        <b>Trendline</b> and <b>R<sup>2</sup></b
        >. Under <b>Label</b>, change <b>Custom</b> to <b>Use Equation</b>.
      </p>

      <p class="mb-3 pl-4">f) What is the value of the slope in your equation?</p>

      <calculation-input
        v-model="inputs.slopeInput"
        prepend-text="$\text{Slope:}$"
        append-text="$\text{K}$"
        class="mb-5 pl-4"
        :disabled="!allowEditing"
      />

      <p class="mb-3 pl-4">
        g) If the value of <stemble-latex content="$\text{R}$" /> is
        <stemble-latex content="$8.3145~\text{J/K}\cdot\text{mol},$" /> what is the value of
        <stemble-latex content="$\Delta\text{H}_\text{vap}?$" />
      </p>

      <calculation-input
        v-model="inputs.deltaHvap"
        prepend-text="$\Delta\text{H}_\text{vap}:$"
        append-text="$\text{kJ/mol}$"
        class="pl-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Chem1LC_LabFundamentals_Q11',
  components: {CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        slopeInput: null,
        deltaHvap: null,
      },
      options1: [
        {text: '=1/B2', value: '=1/B2'},
        {text: '=1/T', value: '=1/T'},
        {text: '1/T', value: '1/T'},
        {text: '=1/C2', value: '=1/C2'},
        {text: 'None of these', value: 'noneA'},
      ],
      options2: [
        {text: 'Click on checkmark for suggested autofill', value: 'checkmarkB'},
        {
          text: 'Click on C2, grab square on bottom right corner and pull down highlighting C3 through C7.',
          value: 'clickDragB',
        },
        {text: 'Either of these', value: 'eitherB'},
      ],
      options3: [
        {text: '=ln(D2)', value: '=ln(D2)'},
        {text: '=lnVP', value: '=lnVP'},
        {text: 'ln(E2)', value: 'ln(E2)'},
        {text: 'ln(D2)', value: 'ln(D2)'},
        {text: 'None of these', value: 'noneC'},
      ],
      options4: [
        {text: 'Click on checkmark for suggested autofill', value: 'checkmarkD'},
        {
          text: 'Click on E2, grab square on bottom right corner and pull down highlighting C3 through C7.',
          value: 'clickDragD',
        },
        {text: 'Either of these', value: 'eitherD'},
      ],
      options5: [
        {text: 'Go to the Insert menu and choose Chart.', value: 'insertMenu'},
        {text: 'Click on the Insert Chart icon in the toolbar', value: 'insertIcon'},
        {text: 'Either of these', value: 'eitherE'},
      ],
    };
  },
  computed: {
    hVapScaled() {
      return this.taskState.getValueBySymbol('hVapScaled').content;
    },
    yInt() {
      return this.taskState.getValueBySymbol('yInt').content;
    },
    slope() {
      return -this.hVapScaled.toFloat() / 3 / 8.3145;
    },
    xValues() {
      return [298.2, 301.1, 305.1, 308.4, 310.8, 313.3];
    },
    lnVpValues() {
      return this.xValues.map(
        (temperature) =>
          Math.exp(this.slope / temperature + this.yInt.toFloat()) + Math.sin(temperature)
      );
    },
  },
};
</script>
